<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Create New Category">
            <validation-observer ref="createCategoryValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          name="name"
                          placeholder="Enter category name"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Image"
                      label-for="image"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="image"
                        rules="required"
                      >
                        <b-form-file
                          id="image"
                          v-model="form.image"
                          accept=".jpg, .png, .gif .svg"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="secondary"
                        type="button"
                        class="mr-1"
                        @click="clearForm"
                      >
                        Reset
                      </b-button>
                      <b-button
                        variant="success"
                        type="submit"
                      >
                        Create
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const mobileTabletModule = createNamespacedHelpers('mobileTablet')

export default {
  data() {
    return {
      loader: false,
      form: {
        name: '',
        image: [],
      },
    }
  },
  methods: {
    ...mobileTabletModule.mapActions(['ADD_CATEGORY']),

    onSubmit() {
      this.$refs.createCategoryValidator.validate().then(async success => {
        if (success) {
          this.loader = true
          const formData = new FormData()
          formData.append('name', this.form.name)
          formData.append('image', this.form.image)
          const resp = await this.ADD_CATEGORY(formData)
          if (resp) {
            this.form.name = ''
            this.form.image = []
            this.$nextTick(() => {
              this.$refs.createCategoryValidator.reset()
            })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Category created!',
                icon: 'checkIcon',
                variant: 'success',
                text: 'The category has been created successfully!',
              },
            })
          }
          this.loader = false
        }
      })
    },
    clearForm() {
      this.form.name = ''
      this.$nextTick(() => {
        this.$refs.createCategoryValidator.reset()
      })
    },
  },
}
</script>
